<template>
  <div class="wrapper">
    <el-row :gutter="16">
      <el-col :sm="6" :xs="12">
        <el-card
          header="应用总数"
          :body-style="{ height: '80px' }"
          style="text-align: center; font-size: 24px"
        >
          <span style="text-align=center;font-size: 32px">{{ appNumber }}</span>
        </el-card>
      </el-col>
      <el-col :sm="6" :xs="12">
        <el-card
          header="产品总数"
          :body-style="{ height: '80px' }"
          style="text-align: center; font-size: 24px"
        >
          <span style="text-align=center;font-size: 32px">{{
            productNumber
          }}</span>
        </el-card>
      </el-col>
      <el-col :sm="6" :xs="12">
        <el-card
          header="设备分组"
          :body-style="{ height: '80px' }"
          style="text-align: center; font-size: 24px"
        >
          <span style="text-align=center;font-size: 32px">{{
            groupNumber
          }}</span>
        </el-card>
      </el-col>
      <el-col :sm="6" :xs="12">
        <el-card
          header="设备总数"
          :body-style="{ height: '80px' }"
          style="text-align: center; font-size: 24px"
        >
          <span style="text-align=center;font-size: 32px">{{
            deviceNumber
          }}</span>
        </el-card>
      </el-col>
    </el-row>

    <el-card :body-style="{ height: '270px' }" style="margin-top: 16px">
      <div slot="header" style="display: flex; justify-content: space-between">
        <span style="font-size: 18px"> 设备使用情况 </span>
        <el-date-picker
          type="daterange"
          v-model="dateRange"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="yyyy-MM-dd"
          @change="onDateRangeChange"
        ></el-date-picker>
      </div>
      <el-row :gutter="16">
        <el-col :sm="18" :xs="24">
          <div ref="chart" style="width: 100%; height: 260px"></div>
        </el-col>
        <el-col :sm="6" :xs="24">
          <h4 style="margin-top: 0; font-weight: normal; font-size: 18px">
            设备分组排行
          </h4>
          <ul class="list">
            <li v-for="(group, index) in groupList" :key="index">
              <span :class="index < 3 ? 'active' : ''">{{ index + 1 }}</span>
              <span>{{ group.group }}</span>
              <span>{{ group.num }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<style scoped>
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list > li {
  padding: 4px 0;
}

.list > li > span:nth-child(1) {
  background-color: #dddddd;
  border-radius: 50%;
  color: #8f8989;
  padding: 2px 8px;
  font-size: 14px;
}

.list > li > span.active {
  background-color: #222222;
  border-radius: 50%;
  color: #ffffff;
  padding: 2px 8px;
  font-size: 14px;
}

.list > li > span:nth-child(2) {
  margin-left: 4px;
  color: #8f8989;
  padding: 2px 8px;
  font-size: 14px;
}

.list > li > span:nth-child(3) {
  margin-left: 4px;
  color: #8f8989;
  padding: 2px 8px;
  font-size: 14px;
  float: right;
}
</style>

<script>
import echarts from "echarts";

export default {
  data() {
    return {
      dateRange: null,
      appNumber: 0,
      productNumber: 0,
      groupNumber: 0,
      deviceNumber: 0,
      groupList: [],
      myChart: null,
    };
  },
  mounted() {
    this.initECharts();
    this.initDefaultDateRange();
    this.getDeviceCount();
    this.getGroupRank();
    this.getDeviceRank();
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    initECharts() {
      this.myChart = echarts.init(this.$refs.chart);
    },
    initDefaultDateRange() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      this.changeTimes(start, end);
    },
    changeTimes(start, end) {
      this.dateRange = [
        start.getFullYear() +
          "-" +
          (start.getMonth() + 1) +
          "-" +
          start.getDate(),
        end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate(),
      ];
    },
    onDateRangeChange() {
      this.changeTimes(this.dateRange[0], this.dateRange[1]);
      this.getGroupRank();
      this.getDeviceRank();
    },
    getDeviceCount() {
      this.$api.statistic.getDeviceCount().then((res) => {
        this.appNumber = res[0].num;
        this.productNumber = res[1].num;
        this.groupNumber = res[2].num;
        this.deviceNumber = res[3].num;
      });
    },
    getDeviceRank() {
      let params = {
        from: this.dateRange[0],
        to: this.dateRange[1],
      };
      this.$api.statistic.getDeviceRank(params).then((res) => {
        let xData = res.timeSegments.map((item) => {
          return item.segment;
        });
        let data = res.timeSegments.map((item) => {
          return item.num;
        });
        this.myChart.setOption({
          color: ["#3AA1FF"],
          tooltip: {},
          xAxis: {
            data: xData,
            axisLine: {
              lineStyle: {
                color: "#BABABA",
              },
            },
          },
          yAxis: {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
          grid: {
            left: 25,
            top: 20,
            bottom: 20,
            right: 20,
          },
          series: [
            {
              name: "使用次数",
              type: "bar",
              barGap: "20px",
              barCategoryGap: "20px",
              data: data,
            },
          ],
        });
      });
    },
    getGroupRank() {
      let params = {
        from: this.dateRange[0],
        to: this.dateRange[1],
      };
      this.$api.statistic.getGroupRank(params).then((res) => {
        this.groupList = res;
      });
    },
  },
};
</script>